export const hsv2rgb = `
    vec3 hsv2rgb(vec3 c){
        vec4 K = vec4(1.0,2.0/3.0,1.0/3.0,3.0);
        vec3 p = abs(fract(c.xxx + K.xyz)*6.0 - K.www);
        return c.z * mix(K.xxx, clamp(p-K.xxx, 0.0,1.0),c.y);
    }
`;

export const rgb2hsv = `
vec3 rgb2hsv(vec3 c) {
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}
`;

export const distortAtPoint = `
uniform float radius;
uniform float distort;
uniform float frequency;
uniform float surfaceDistort;
uniform float surfaceFrequency;
uniform float surfaceTime;
uniform float surfaceSpeed;
uniform float numberOfWaves;
uniform float fixNormals;
uniform float surfacePoleAmount;
uniform float gooPoleAmount;

// float radius = 0.5;
// float distort = 0.5;
// float frequency = 0.5;
// float surfaceDistort = 0.5;
// float surfaceFrequency = 0.5;
// float surfaceTime = 0.5;
// float surfaceSpeed = 0.5;
// float numberOfWaves = 0.5;
// float fixNormals = 0.5;
// float surfacePoleAmount = 0.5;
// float gooPoleAmount = 0.5;

#define M_PI 3.1415926538
#define NOISE_PERIOD 10.

float distortAtPoint(vec3 point) {
    float yPos = smoothstep(-1., 1., point.y);
    float amount = sin(yPos * M_PI);
    float wavePoleAmount = mix(amount * 1.0, 1.0, surfacePoleAmount);
    float gooPoleAmount = mix(amount * 1.0, 1.0, gooPoleAmount);
    // blob noise
    float goo = pnoise(vec3(point / (frequency) + mod(time, NOISE_PERIOD)), vec3(NOISE_PERIOD)) * pow(distort, 2.0);
    // wave noise
    float surfaceNoise = pnoise(vec3(point / (surfaceFrequency) + mod(surfaceTime * surfaceSpeed, NOISE_PERIOD)), vec3(NOISE_PERIOD));
    float waves = (point.x * sin((point.y+surfaceNoise)*M_PI*numberOfWaves) + point.z * cos((point.y+surfaceNoise)*M_PI*numberOfWaves)) * 0.01 * pow(surfaceDistort, 2.0);
    // combined noise
    return waves * wavePoleAmount + goo * gooPoleAmount;
}
vec3 orthogonal(vec3 v) {
    return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0) : vec3(0.0, -v.z, v.y));
}
`;

export const range = `
float range(float oldValue, float oldMin, float oldMax, float newMin, float newMax) {
    vec3 sub = vec3(oldValue, newMax, oldMax) - vec3(oldMin, newMin, oldMin);
    return sub.x * sub.y / sub.z + newMin;
}
vec2 range(vec2 oldValue, vec2 oldMin, vec2 oldMax, vec2 newMin, vec2 newMax) {
    vec2 oldRange = oldMax - oldMin;
    vec2 newRange = newMax - newMin;
    vec2 val = oldValue - oldMin;
    return val * newRange / oldRange + newMin;
}
vec3 range(vec3 oldValue, vec3 oldMin, vec3 oldMax, vec3 newMin, vec3 newMax) {
    vec3 oldRange = oldMax - oldMin;
    vec3 newRange = newMax - newMin;
    vec3 val = oldValue - oldMin;
    return val * newRange / oldRange + newMin;
}
float crange(float oldValue, float oldMin, float oldMax, float newMin, float newMax) {
    return clamp(range(oldValue, oldMin, oldMax, newMin, newMax), min(newMin, newMax), max(newMin, newMax));
}
vec2 crange(vec2 oldValue, vec2 oldMin, vec2 oldMax, vec2 newMin, vec2 newMax) {
    return clamp(range(oldValue, oldMin, oldMax, newMin, newMax), min(newMin, newMax), max(newMin, newMax));
}
vec3 crange(vec3 oldValue, vec3 oldMin, vec3 oldMax, vec3 newMin, vec3 newMax) {
    return clamp(range(oldValue, oldMin, oldMax, newMin, newMax), min(newMin, newMax), max(newMin, newMax));
}
float rangeTransition(float t, float x, float padding) {
    float transition = crange(t, 0.0, 1.0, -padding, 1.0 + padding);
    return crange(x, transition - padding, transition + padding, 1.0, 0.0);
}
`;

export const flow = `
vec3 mod289(vec3 x){
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}
vec4 mod289(vec4 x){
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}
vec4 perm(vec4 x){
    return mod289(((x * 34.0) + 1.0) * x);
}
float noise(vec3 p){    
    vec3 a = floor(p);    
    vec3 d = p - a;    
    d = d * d * (3.0 - 2.0 * d);    
    vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);    
    vec4 k1 = perm(b.xyxy);    
    vec4 k2 = perm(k1.xyxy + b.zzww);    
    vec4 c = k2 + a.zzzz;    
    vec4 k3 = perm(c);    
    vec4 k4 = perm(c + 1.0);    
    vec4 o1 = fract(k3 * (1.0 / 41.0));    
    vec4 o2 = fract(k4 * (1.0 / 41.0));    
    vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);    
    vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);    
    return o4.y * d.y + o4.x * (1.0 - d.y);
    // return o4.y * d.y + o4.x * (1.0 - d.y);
}
float flow(in vec3 p, in float t){
    mat3 m3 = mat3( 0.00,  0.80,  0.60,-0.80,  0.36, -0.48,-0.60, -0.48,  0.64 );
    // mat3 m3 = mat3( 0.00,  0.80,  0.60,-0.80,  0.36, -0.48,-0.60, -0.48,  0.64 );
    float z=2.;
    float rz = 0.;
    vec3 bp = p;
    for (float i= 1.;i < 3.;i++ )    {
        p += t*.1;
        rz+= (sin(noise(p+t*0.8)*6.)*0.5+0.5) /z;
        // rz+= (sin(noise(p+t*0.8)*6.)*0.5+0.5) /z;
        p = mix(bp,p,0.6);
        z *= 2.;
        p *= 2.01;
        p*= m3;
    }
    return rz;
}
float flow(vec2 uv, vec2 resolution, float time, float dist) {
    vec2 p = (uv - vec2(.5)) * 20. * resolution;
    // vec2 p = (uv - vec2(.5)) * 75. * resolution;
    vec3 ro = vec3(0.,0.,dist);
    vec3 rd = normalize(vec3(p*.5,-1.));
    vec3 pos = ro+rd;
    vec3 rf = reflect( rd, pos );
    float nz = (-log(abs(flow(rf*1.,time * .5)-.1)));
    // float nz = (-log(abs(flow(rf*2.,time * .5)-.01)));
    return (0.1*nz);
    // return (0.1*nz*nz)*0.5;
}
`;

export const equirectUv = `
vec2 equirectUv( in vec3 dir ) {
    // dir is assumed to be unit length
    float u = atan( dir.z, dir.x ) * RECIPROCAL_PI2 + 0.5;
    float v = asin( clamp( dir.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    return vec2( u, v );
}
`;

export const cnoise = `
vec4 permute(vec4 x){
    return mod(((x*34.0)+1.0)*x, 289.0);
}
vec4 taylorInvSqrt(vec4 r){
    return 1.79284291400159 - 0.85373472095314 * r;
}
vec4 fade(vec4 t) {
    return t*t*t*(t*(t*6.0-15.0)+10.0);
}
vec3 mod289(vec3 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}
vec4 mod289(vec4 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}
vec3 fade(vec3 t) {
    return t*t*t*(t*(t*6.0-15.0)+10.0);
}
float cnoise(vec3 P){
    vec3 Pi0 = floor(P);
    // Integer part for indexing
    vec3 Pi1 = Pi0 + vec3(1.0);
    // Integer part + 1
    Pi0 = mod289(Pi0);
    Pi1 = mod289(Pi1);
    vec3 Pf0 = fract(P);
    // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0);
    // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;
    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);
    vec4 gx0 = ixy0 * (1.0 / 7.0);
    vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);
    vec4 gx1 = ixy1 * (1.0 / 7.0);
    vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);
    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;
    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);
    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
    return 2.2 * n_xyz;
}
float cnoise(vec4 P, vec4 rep){
    vec4 Pi0 = mod(floor(P), rep);
    // Integer part modulo rep
    vec4 Pi1 = mod(Pi0 + 1.0, rep);
    // Integer part + 1 mod rep
    vec4 Pf0 = fract(P);
    // Fractional part for interpolation
    vec4 Pf1 = Pf0 - 1.0;
    // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = vec4(Pi0.zzzz);
    vec4 iz1 = vec4(Pi1.zzzz);
    vec4 iw0 = vec4(Pi0.wwww);
    vec4 iw1 = vec4(Pi1.wwww);
    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);
    vec4 ixy00 = permute(ixy0 + iw0);
    vec4 ixy01 = permute(ixy0 + iw1);
    vec4 ixy10 = permute(ixy1 + iw0);
    vec4 ixy11 = permute(ixy1 + iw1);
    vec4 gx00 = ixy00 / 7.0;
    vec4 gy00 = floor(gx00) / 7.0;
    vec4 gz00 = floor(gy00) / 6.0;
    gx00 = fract(gx00) - 0.5;
    gy00 = fract(gy00) - 0.5;
    gz00 = fract(gz00) - 0.5;
    vec4 gw00 = vec4(0.75) - abs(gx00) - abs(gy00) - abs(gz00);
    vec4 sw00 = step(gw00, vec4(0.0));  gx00 -= sw00 * (step(0.0, gx00) - 0.5);
    gy00 -= sw00 * (step(0.0, gy00) - 0.5);
    vec4 gx01 = ixy01 / 7.0;
    vec4 gy01 = floor(gx01) / 7.0;
    vec4 gz01 = floor(gy01) / 6.0;
    gx01 = fract(gx01) - 0.5;
    gy01 = fract(gy01) - 0.5;
    gz01 = fract(gz01) - 0.5;
    vec4 gw01 = vec4(0.75) - abs(gx01) - abs(gy01) - abs(gz01);
    vec4 sw01 = step(gw01, vec4(0.0));  gx01 -= sw01 * (step(0.0, gx01) - 0.5);
    gy01 -= sw01 * (step(0.0, gy01) - 0.5);
    vec4 gx10 = ixy10 / 7.0;
    vec4 gy10 = floor(gx10) / 7.0;
    vec4 gz10 = floor(gy10) / 6.0;
    gx10 = fract(gx10) - 0.5;
    gy10 = fract(gy10) - 0.5;
    gz10 = fract(gz10) - 0.5;
    vec4 gw10 = vec4(0.75) - abs(gx10) - abs(gy10) - abs(gz10);
    vec4 sw10 = step(gw10, vec4(0.0));
    gx10 -= sw10 * (step(0.0, gx10) - 0.5);
    gy10 -= sw10 * (step(0.0, gy10) - 0.5);
    vec4 gx11 = ixy11 / 7.0;
    vec4 gy11 = floor(gx11) / 7.0;
    vec4 gz11 = floor(gy11) / 6.0;
    gx11 = fract(gx11) - 0.5;
    gy11 = fract(gy11) - 0.5;
    gz11 = fract(gz11) - 0.5;
    vec4 gw11 = vec4(0.75) - abs(gx11) - abs(gy11) - abs(gz11);
    vec4 sw11 = step(gw11, vec4(0.0));  gx11 -= sw11 * (step(0.0, gx11) - 0.5);
    gy11 -= sw11 * (step(0.0, gy11) - 0.5);
    vec4 g0000 = vec4(gx00.x,gy00.x,gz00.x,gw00.x);
    vec4 g1000 = vec4(gx00.y,gy00.y,gz00.y,gw00.y);
    vec4 g0100 = vec4(gx00.z,gy00.z,gz00.z,gw00.z);
    vec4 g1100 = vec4(gx00.w,gy00.w,gz00.w,gw00.w);
    vec4 g0010 = vec4(gx10.x,gy10.x,gz10.x,gw10.x);
    vec4 g1010 = vec4(gx10.y,gy10.y,gz10.y,gw10.y);
    vec4 g0110 = vec4(gx10.z,gy10.z,gz10.z,gw10.z);
    vec4 g1110 = vec4(gx10.w,gy10.w,gz10.w,gw10.w);
    vec4 g0001 = vec4(gx01.x,gy01.x,gz01.x,gw01.x);
    vec4 g1001 = vec4(gx01.y,gy01.y,gz01.y,gw01.y);
    vec4 g0101 = vec4(gx01.z,gy01.z,gz01.z,gw01.z);
    vec4 g1101 = vec4(gx01.w,gy01.w,gz01.w,gw01.w);
    vec4 g0011 = vec4(gx11.x,gy11.x,gz11.x,gw11.x);
    vec4 g1011 = vec4(gx11.y,gy11.y,gz11.y,gw11.y);
    vec4 g0111 = vec4(gx11.z,gy11.z,gz11.z,gw11.z);
    vec4 g1111 = vec4(gx11.w,gy11.w,gz11.w,gw11.w);
    vec4 norm00 = taylorInvSqrt(vec4(dot(g0000, g0000), dot(g0100, g0100), dot(g1000, g1000), dot(g1100, g1100)));
    g0000 *= norm00.x;
    g0100 *= norm00.y;
    g1000 *= norm00.z;
    g1100 *= norm00.w;
    vec4 norm01 = taylorInvSqrt(vec4(dot(g0001, g0001), dot(g0101, g0101), dot(g1001, g1001), dot(g1101, g1101)));
    g0001 *= norm01.x;
    g0101 *= norm01.y;
    g1001 *= norm01.z;
    g1101 *= norm01.w;
    vec4 norm10 = taylorInvSqrt(vec4(dot(g0010, g0010), dot(g0110, g0110), dot(g1010, g1010), dot(g1110, g1110)));
    g0010 *= norm10.x;
    g0110 *= norm10.y;
    g1010 *= norm10.z;
    g1110 *= norm10.w;
    vec4 norm11 = taylorInvSqrt(vec4(dot(g0011, g0011), dot(g0111, g0111), dot(g1011, g1011), dot(g1111, g1111)));
    g0011 *= norm11.x;
    g0111 *= norm11.y;
    g1011 *= norm11.z;
    g1111 *= norm11.w;
    float n0000 = dot(g0000, Pf0);
    float n1000 = dot(g1000, vec4(Pf1.x, Pf0.yzw));
    float n0100 = dot(g0100, vec4(Pf0.x, Pf1.y, Pf0.zw));
    float n1100 = dot(g1100, vec4(Pf1.xy, Pf0.zw));
    float n0010 = dot(g0010, vec4(Pf0.xy, Pf1.z, Pf0.w));
    float n1010 = dot(g1010, vec4(Pf1.x, Pf0.y, Pf1.z, Pf0.w));
    float n0110 = dot(g0110, vec4(Pf0.x, Pf1.yz, Pf0.w));
    float n1110 = dot(g1110, vec4(Pf1.xyz, Pf0.w));
    float n0001 = dot(g0001, vec4(Pf0.xyz, Pf1.w));
    float n1001 = dot(g1001, vec4(Pf1.x, Pf0.yz, Pf1.w));
    float n0101 = dot(g0101, vec4(Pf0.x, Pf1.y, Pf0.z, Pf1.w));
    float n1101 = dot(g1101, vec4(Pf1.xy, Pf0.z, Pf1.w));
    float n0011 = dot(g0011, vec4(Pf0.xy, Pf1.zw));
    float n1011 = dot(g1011, vec4(Pf1.x, Pf0.y, Pf1.zw));
    float n0111 = dot(g0111, vec4(Pf0.x, Pf1.yzw));
    float n1111 = dot(g1111, Pf1);
    vec4 fade_xyzw = fade(Pf0);
    vec4 n_0w = mix(vec4(n0000, n1000, n0100, n1100), vec4(n0001, n1001, n0101, n1101), fade_xyzw.w);
    vec4 n_1w = mix(vec4(n0010, n1010, n0110, n1110), vec4(n0011, n1011, n0111, n1111), fade_xyzw.w);
    vec4 n_zw = mix(n_0w, n_1w, fade_xyzw.z);  vec2 n_yzw = mix(n_zw.xy, n_zw.zw, fade_xyzw.y);
    float n_xyzw = mix(n_yzw.x, n_yzw.y, fade_xyzw.x);  return 2.2 * n_xyzw;
}

`;

export const snoise = `
//\tSimplex 3D Noise 
    //\tby Ian McEwan, Ashima Arts
    //
    vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
    vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
    
    float snoise(vec3 v){ 
      const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
      const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
    
    // First corner
      vec3 i  = floor(v + dot(v, C.yyy) );
      vec3 x0 =   v - i + dot(i, C.xxx) ;
    
    // Other corners
      vec3 g = step(x0.yzx, x0.xyz);
      vec3 l = 1.0 - g;
      vec3 i1 = min( g.xyz, l.zxy );
      vec3 i2 = max( g.xyz, l.zxy );
    
      //  x0 = x0 - 0. + 0.0 * C 
      vec3 x1 = x0 - i1 + 1.0 * C.xxx;
      vec3 x2 = x0 - i2 + 2.0 * C.xxx;
      vec3 x3 = x0 - 1. + 3.0 * C.xxx;
    
    // Permutations
      i = mod(i, 289.0 ); 
      vec4 p = permute( permute( permute( 
                 i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
               + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
               + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
    
    // Gradients
    // ( N*N points uniformly over a square, mapped onto an octahedron.)
      float n_ = 1.0/7.0; // N=7
      vec3  ns = n_ * D.wyz - D.xzx;
    
      vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)
    
      vec4 x_ = floor(j * ns.z);
      vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
    
      vec4 x = x_ *ns.x + ns.yyyy;
      vec4 y = y_ *ns.x + ns.yyyy;
      vec4 h = 1.0 - abs(x) - abs(y);
    
      vec4 b0 = vec4( x.xy, y.xy );
      vec4 b1 = vec4( x.zw, y.zw );
    
      vec4 s0 = floor(b0)*2.0 + 1.0;
      vec4 s1 = floor(b1)*2.0 + 1.0;
      vec4 sh = -step(h, vec4(0.0));
    
      vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
      vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
    
      vec3 p0 = vec3(a0.xy,h.x);
      vec3 p1 = vec3(a0.zw,h.y);
      vec3 p2 = vec3(a1.xy,h.z);
      vec3 p3 = vec3(a1.zw,h.w);
    
    //Normalise gradients
      vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
      p0 *= norm.x;
      p1 *= norm.y;
      p2 *= norm.z;
      p3 *= norm.w;
    
    // Mix final noise value
      vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
      m = m * m;
      return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                    dot(p2,x2), dot(p3,x3) ) );
    }`;

export const pnoise = `
vec3 mod289(vec3 x){
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}
vec4 mod289(vec4 x){
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}
vec4 permute(vec4 x){
    return mod289(((x*34.0)+1.0)*x);
}
vec4 taylorInvSqrt(vec4 r){
    return 1.79284291400159 - 0.85373472095314 * r;
}
vec3 fade(vec3 t) {
    return t*t*t*(t*(t*6.0-15.0)+10.0);
}
// Classic Perlin noise, periodic variant
float pnoise(vec3 P, vec3 rep){
    vec3 Pi0 = mod(floor(P), rep);
    // Integer part, modulo period
    vec3 Pi1 = mod(Pi0 + vec3(1.0), rep);
    // Integer part + 1, mod period
    Pi0 = mod289(Pi0);
    Pi1 = mod289(Pi1);  vec3 Pf0 = fract(P);
    // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0);
    // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;
    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);
    vec4 gx0 = ixy0 * (1.0 / 7.0);
    vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);
    vec4 gx1 = ixy1 * (1.0 / 7.0);
    vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);
    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;
    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);
    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
    float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);

    return 2.2 * n_xyz;
}
`;

export const utils = `
const float PI = 3.14159265359;
const float RECIPROCAL_PI = 0.31830988618;
const float RECIPROCAL_PI2 = 0.15915494;
const float LN2 = 0.6931472;
const float ENV_LODS = 6.0;
vec4 SRGBtoLinear(vec4 srgb) {
    vec3 linOut = pow(srgb.xyz, vec3(2.2));
    return vec4(linOut, srgb.w);
}
vec3 linearToSRGB(vec3 color) {
    return pow(color, vec3(1.0 / 2.2));
}
vec4 RGBEToLinear(vec4 value) {
    return vec4(value.rgb * exp2(value.a * 255.0 - 128.0), 1.0);
}
vec4 RGBMToLinear(vec4 value) {
    float maxRange = 6.0;
    return vec4(value.xyz * value.w * maxRange, 1.0);
}
vec4 RGBDToLinear(vec4 value) {
    float maxRange = 6.0;
    return vec4(value.rgb * ((maxRange / 255.0) / value.a), 1.0);
}
vec3 getNormal() {
    vec3 pos_dx = dFdx(vMPos.xyz);
    vec3 pos_dy = dFdy(vMPos.xyz);
    vec2 tex_dx = dFdx(vUv);
    vec2 tex_dy = dFdy(vUv);
    vec3 t = normalize(pos_dx * tex_dy.t - pos_dy * tex_dx.t);
    vec3 b = normalize(-pos_dx * tex_dy.s + pos_dy * tex_dx.s);
    mat3 tbn = mat3(t, b, normalize(vNormal));
    vec3 n = texture(tNormal, vUv * uNormalUVScale).rgb * 2.0 - 1.0;
    n.xy *= uNormalScale;    vec3 normal = normalize(tbn * n);
    // Get world normal from view normal (normalMatrix * normal)
    return normalize((vec4(normal, 0.0) * viewMatrix).xyz);
}
vec3 specularReflection(vec3 specularEnvR0, vec3 specularEnvR90, float VdH) {
    return specularEnvR0 + (specularEnvR90 - specularEnvR0) * pow(clamp(1.0 - VdH, 0.0, 1.0), 5.0);
}
float geometricOcclusion(float NdL, float NdV, float roughness) {
    float r = roughness;    float attenuationL = 2.0 * NdL / (NdL + sqrt(r * r + (1.0 - r * r) * (NdL * NdL)));
    float attenuationV = 2.0 * NdV / (NdV + sqrt(r * r + (1.0 - r * r) * (NdV * NdV)));
    return attenuationL * attenuationV;
}
float microfacetDistribution(float roughness, float NdH) {
    float roughnessSq = roughness * roughness;
    float f = (NdH * roughnessSq - NdH) * NdH + 1.0;
    return roughnessSq / (PI * f * f);
}
vec2 cartesianToPolar(vec3 n) {
    vec2 uv;
    uv.x = atan(n.z, n.x) * RECIPROCAL_PI2 + 0.5;
    uv.y = asin(n.y) * RECIPROCAL_PI + 0.5;
    return uv;
}
void getIBLContribution(inout vec3 diffuse, inout vec3 specular, float NdV, float roughness, vec3 n, vec3 reflection, vec3 diffuseColor, vec3 specularColor) {
    vec3 brdf = SRGBtoLinear(texture(tLUT, vec2(NdV, roughness))).rgb;
    // Sample 2 levels and mix between to get smoother degradation
    float blend = roughness * ENV_LODS;    float level0 = floor(blend);
    float level1 = min(ENV_LODS, level0 + 1.0);    blend -= level0;
    // Sample the specular env map atlas depending on the roughness value
    vec2 uvSpec = cartesianToPolar(reflection);
    uvSpec.y /= 2.0;
    vec2 uv0 = uvSpec;
    vec2 uv1 = uvSpec;
    uv0 /= pow(2.0, level0);
    uv0.y += 1.0 - exp(-LN2 * level0);
    uv1 /= pow(2.0, level1);
    uv1.y += 1.0 - exp(-LN2 * level1);
    vec3 diffuseLight;
    vec3 specular0;
    vec3 specular1;
    // 'If else' statements caused the strangest gpu bug
    // if (uInputType < 0.5) {
    //
    //
    // sRGB == 0
    //     diffuseLight = SRGBToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = SRGBToLinear(texture(tEnvSpecular, uv0)).rgb;
    //     specular1 = SRGBToLinear(texture(tEnvSpecular, uv1)).rgb;
    // } else if (uInputType < 1.5) {
    //
    //
    // RGBE == 1
    //     diffuseLight = RGBEToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = RGBEToLinear(texture(tEnvSpecular, uv0)).rgb;
    //     specular1 = RGBEToLinear(texture(tEnvSpecular, uv1)).rgb;
    // } else if (uInputType < 2.5) {
    //
    //
    // RGBM == 2
    //     diffuseLight = RGBMToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = RGBMToLinear(texture(tEnvSpecular, uv0)).rgb;
    //     specular1 = RGBMToLinear(texture(tEnvSpecular, uv1)).rgb;
    // } else if (uInputType < 3.5) {
    //
    //
    // RGBD == 3
    //     diffuseLight = RGBDToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = RGBDToLinear(texture(tEnvSpecular, uv0)).rgb;
    //     specular1 = RGBDToLinear(texture(tEnvSpecular, uv1)).rgb;
    // }
    // sRGB == 0
    diffuseLight = SRGBtoLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    specular0 = SRGBtoLinear(texture(tEnvSpecular, uv0)).rgb;
    specular1 = SRGBtoLinear(texture(tEnvSpecular, uv1)).rgb;
    // RGBE == 1
    float mixRGBE = clamp(1.0 - abs(uInputType - 1.0), 0.0, 1.0);
    diffuseLight = mix(diffuseLight, RGBEToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb, mixRGBE);
    specular0 = mix(specular0, RGBEToLinear(texture(tEnvSpecular, uv0)).rgb, mixRGBE);
    specular1 = mix(specular1, RGBEToLinear(texture(tEnvSpecular, uv1)).rgb, mixRGBE);
    // RGBM == 2
    float mixRGBM = clamp(1.0 - abs(uInputType - 2.0), 0.0, 1.0);
    diffuseLight = mix(diffuseLight, RGBMToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb, mixRGBM);
    specular0 = mix(specular0, RGBMToLinear(texture(tEnvSpecular, uv0)).rgb, mixRGBM);
    specular1 = mix(specular1, RGBMToLinear(texture(tEnvSpecular, uv1)).rgb, mixRGBM);
    // RGBD == 3
    float mixRGBD = clamp(1.0 - abs(uInputType - 3.0), 0.0, 1.0);
    diffuseLight = mix(diffuseLight, RGBDToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb, mixRGBD);
    specular0 = mix(specular0, RGBDToLinear(texture(tEnvSpecular, uv0)).rgb, mixRGBD);
    specular1 = mix(specular1, RGBDToLinear(texture(tEnvSpecular, uv1)).rgb, mixRGBD);
    vec3 specularLight = mix(specular0, specular1, blend);
    diffuse = diffuseLight * diffuseColor;
    // Bit of extra reflection for smooth materials
    float reflectivity = pow((1.0 - roughness), 2.0) * 0.05;
    specular = specularLight * (specularColor * brdf.x + brdf.y + reflectivity);
    specular *= uEnvSpecular;
}
`;

export const vertexBackground = `
precision highp float;
precision highp int;
// attribute vec3 position;
// attribute vec2 uv;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;

varying vec2 vUv;
varying vec3 vPos;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

export const fragmentBackground = `
precision highp float;
const float PI = 3.14159265359;
const float RECIPROCAL_PI = 0.31830988618;
const float RECIPROCAL_PI2 = 0.15915494;
const float LN2 = 0.6931472;

uniform float time;
uniform vec2 uRatio;
uniform float uTransition;
uniform vec3 uColor;

varying vec2 vUv;
varying vec3 vPos;

// #require(cnoise.glsl);
// #require(equirectUv.glsl);
// #require(range.glsl);
${range}
// #require(rgb2hsv.glsl);
${rgb2hsv}
${hsv2rgb}
// #require(flow.glsl);
${flow}

void main() {

    vec2 uv = vUv;
    float center = length( (uv - vec2(.5))* uRatio);
    center = smoothstep(.06, .5, center);
    center = crange(center, 0., .7, 0., 1.);
    // vec3 color = rgb2hsv(vec3(0.,0.,1.));
    // vec3 color = rgb2hsv(vec3(0.11,0.62,0.96));
    // color.g += time * .05;
    // color.r += time * .05;
    // color = hsv2rgb(color);
    // vec3 color = vec3(0.11,0.62,0.96);
    vec3 color = uColor;//vec3(0.02,0.18,0.32);
    float diffuse = clamp(flow(uv, uRatio, time, mix(2.,4.,uTransition)) * center, 0., 1.2);
    // float diffuse = flow(uv, uRatio, time, uTransition);
    
    gl_FragColor = vec4(vec3(center * diffuse * color), 1.0);
}
`;

export const vertexBalls = `
#define RECIPROCAL_PI 0.3183098861837907
#define RECIPROCAL_PI2 0.15915494309189535
uniform float time;
${equirectUv}
${pnoise}
${distortAtPoint}
mat2 rotation2d(float angle) {
    float s = sin(angle);
    float c = cos(angle);
    return mat2(c, -s, s, c);
}
vec2 rotate(vec2 v, float angle) {
    return rotation2d(angle) * v;
}
mat3 rotation3dZ(float angle) {
    float s = sin(angle);
    float c = cos(angle);
    return mat3(c, s, 0.0,-s, c, 0.0,0.0, 0.0, 1.0);
}
vec3 rotateZ(vec3 v, float angle) {
    return rotation3dZ(angle) * v;
}
mat3 rotation3dY(float angle) {
    float s = sin(angle);
    float c = cos(angle);
    return mat3(c, 0.0, -s,0.0, 1.0, 0.0,s, 0.0, c);
}
vec3 rotateY(vec3 v, float angle) {
    return rotation3dY(angle) * v;
}
mat3 rotation3dX(float angle) {
    float s = sin(angle);
    float c = cos(angle);
    return mat3(1.0, 0.0, 0.0,0.0, c, s,0.0, -s, c);
}
vec3 rotateX(vec3 v, float angle) {
    return rotation3dX(angle) * v;
}
float range(float oldValue, float oldMin, float oldMax, float newMin, float newMax) {
    vec3 sub = vec3(oldValue, newMax, oldMax) - vec3(oldMin, newMin, oldMin);
    return sub.x * sub.y / sub.z + newMin;
}
float crange(float oldValue, float oldMin, float oldMax, float newMin, float newMax) {
    return clamp(range(oldValue, oldMin, oldMax, newMin, newMax), min(newMin, newMax), max(newMin, newMax));
}

//#require(distort.glsl);
// attribute vec3 position;
// attribute vec3 normal;
// attribute vec2 uv;
attribute vec3 vHandPos;
attribute vec3 vHandNormal;
attribute vec2 vHandUV;

// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 modelMatrix;
// uniform mat3 normalMatrix;
// uniform vec3 cameraPosition;
uniform float uNoiseDensity;
uniform float uNoiseStrength;
uniform float uTransition;
uniform float uScaleDist;

varying vec3 vNormal;
varying vec3 vPos;
varying vec2 vUv;
varying float vDistort;
varying vec3 vMPos;
varying vec3 vWorldNormal;
varying vec3 vViewDirection;

void main() {
    vec3 p = position;
    // vec3 p = mix(position, vHandPos, uTransition);
    vec3 n = normal;
    float distortion = uScaleDist*distortAtPoint(p);
    vec3 pos = p + (n * distortion);
    // gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
    float offset = 2. / 200.;
    vec3 tangent = orthogonal(n);
    vec3 bitangent = normalize(cross(n, tangent));
    vec3 neighbour1 = p + tangent * offset;
    vec3 neighbour2 = p + bitangent * offset;
    vec3 displacedNeighbour1 = neighbour1 + n * distortAtPoint(neighbour1);
    vec3 displacedNeighbour2 = neighbour2 + n * distortAtPoint(neighbour2);
    vec3 displacedTangent = displacedNeighbour1 - pos;
    vec3 displacedBitangent = displacedNeighbour2 - pos;
    vec3 displacedNormal = normalize(cross(displacedTangent, displacedBitangent));
    vec4 mPos = modelMatrix * vec4(pos, 1.0);
    vMPos = mPos.xyz / mPos.w;
    vWorldNormal = normalize(modelMatrix * vec4(displacedNormal, 0.0)).xyz;
    vViewDirection = normalize(cameraPosition - mPos.xyz);
    vNormal = displacedNormal;
    // vNormal = normal;
    vDistort = distortion;
    vUv = uv;
    vPos = pos;
}
`;

export const fragmentBalls = `
// #extension GL_OES_standard_derivatives : enable
precision highp float;
uniform float time;

varying vec3 vNormal;
varying vec3 vPos;
varying vec2 vUv;
varying float vDistort;
//varying float vAngle;
varying vec3 vMPos;
varying vec3 vWorldNormal;
varying vec3 vViewDirection;

// uniform vec3 cameraPosition;
//uniform mat4 viewMatrix;
uniform vec3 uBaseColor;
uniform sampler2D tRMO;
uniform float uMetallic;
uniform float uRoughness;
uniform float uOcclusion;
uniform sampler2D tNormal;
uniform float uNormalScale;
uniform float uNormalUVScale;
uniform sampler2D tLUT;
uniform sampler2D tEnvDiffuse;
uniform sampler2D tEnvSpecular;
uniform float uEnvSpecular;
uniform float uOpacity;
uniform float uInputType;
uniform vec3 uLightDirection;
//uniform vec3 uLightColor;
uniform vec3 uFesnelColor;

// #define RECIPROCAL_PI 0.3183098861837907
// #define RECIPROCAL_PI2 0.15915494309189535

const float PI = 3.14159265359;
const float RECIPROCAL_PI = 0.31830988618;
const float RECIPROCAL_PI2 = 0.15915494;
const float LN2 = 0.6931472;
const float ENV_LODS = 6.0;

${equirectUv}
${hsv2rgb}
${pnoise}
${distortAtPoint}
${rgb2hsv}
${range}

vec4 SRGBtoLinear(vec4 srgb) {
    vec3 linOut = pow(srgb.xyz, vec3(2.2));
    return vec4(linOut, srgb.w);
}

vec4 RGBDToLinear(vec4 value) {
    float maxRange = 6.0;
    return vec4(value.rgb * ((maxRange / 255.0) / value.a), 1.0);
}

vec3 getNormal() {
    vec3 pos_dx = dFdx(vMPos.xyz);
    vec3 pos_dy = dFdy(vMPos.xyz);
    vec2 tex_dx = dFdx(vUv);
    vec2 tex_dy = dFdy(vUv);
    vec3 t = normalize(pos_dx * tex_dy.t - pos_dy * tex_dx.t);
    vec3 b = normalize(-pos_dx * tex_dy.s + pos_dy * tex_dx.s);
    mat3 tbn = mat3(t, b, normalize(vNormal));
    vec3 n = texture(tNormal, vUv * uNormalUVScale).rgb * 2.0 - 1.0;
    n.xy *= uNormalScale;    vec3 normal = normalize(tbn * n);
    // Get world normal from view normal (normalMatrix * normal)
    return normalize((vec4(normal, 0.0) * viewMatrix).xyz);
}

vec3 specularReflection(vec3 specularEnvR0, vec3 specularEnvR90, float VdH) {
    return specularEnvR0 + (specularEnvR90 - specularEnvR0) * pow(clamp(1.0 - VdH, 0.0, 1.0), 5.0);
}

float geometricOcclusion(float NdL, float NdV, float roughness) {
    float r = roughness;    float attenuationL = 2.0 * NdL / (NdL + sqrt(r * r + (1.0 - r * r) * (NdL * NdL)));
    float attenuationV = 2.0 * NdV / (NdV + sqrt(r * r + (1.0 - r * r) * (NdV * NdV)));
    return attenuationL * attenuationV;
}
float microfacetDistribution(float roughness, float NdH) {
    float roughnessSq = roughness * roughness;
    float f = (NdH * roughnessSq - NdH) * NdH + 1.0;
    return roughnessSq / (PI * f * f);
}
vec4 RGBMToLinear(vec4 value) {
    float maxRange = 6.0;
    return vec4(value.xyz * value.w * maxRange, 1.0);
}

vec2 cartesianToPolar(vec3 n) {
    vec2 uv;
    uv.x = atan(n.z, n.x) * RECIPROCAL_PI2 + 0.5;
    uv.y = asin(n.y) * RECIPROCAL_PI + 0.5;
    return uv;
}

void getIBLContribution(inout vec3 diffuse, inout vec3 specular, float NdV, float roughness, vec3 n, vec3 reflection, vec3 diffuseColor, vec3 specularColor) {
    vec3 brdf = SRGBtoLinear(texture(tLUT, vec2(NdV, roughness))).rgb;
    // Sample 2 levels and mix between to get smoother degradation
    float blend = roughness * ENV_LODS;    float level0 = floor(blend);
    float level1 = min(ENV_LODS, level0 + 1.0);    blend -= level0;
    // Sample the specular env map atlas depending on the roughness value
    vec2 uvSpec = cartesianToPolar(reflection);
    uvSpec.y /= 2.0;
    vec2 uv0 = uvSpec;
    vec2 uv1 = uvSpec;
    uv0 /= pow(2.0, level0);
    uv0.y += 1.0 - exp(-LN2 * level0);
    uv1 /= pow(2.0, level1);
    uv1.y += 1.0 - exp(-LN2 * level1);
    vec3 diffuseLight;
    vec3 specular0;
    vec3 specular1;
    // 'If else' statements caused the strangest gpu bug
    // if (uInputType < 0.5) {
    //
    //
    // sRGB == 0
    //     diffuseLight = SRGBToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = SRGBToLinear(texture(tEnvSpecular, uv0)).rgb;
    //     specular1 = SRGBToLinear(texture(tEnvSpecular, uv1)).rgb;
    // } else if (uInputType < 1.5) {
    //
    //
    // RGBE == 1
    //     diffuseLight = RGBEToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = RGBEToLinear(texture(tEnvSpecular, uv0)).rgb;
    //     specular1 = RGBEToLinear(texture(tEnvSpecular, uv1)).rgb;
    // } else if (uInputType < 2.5) {
    //
    //
    // RGBM == 2
    //     diffuseLight = RGBMToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = RGBMToLinear(texture(tEnvSpecular, uv0)).rgb;
    //     specular1 = RGBMToLinear(texture(tEnvSpecular, uv1)).rgb;
    // } else if (uInputType < 3.5) {
    //
    //
    // RGBD == 3
    //     diffuseLight = RGBDToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = RGBDToLinear(texture(tEnvSpecular, uv0)).rgb;
    //     specular1 = RGBDToLinear(texture(tEnvSpecular, uv1)).rgb;
    // }
    // sRGB == 0
    diffuseLight = SRGBtoLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb;
    specular0 = SRGBtoLinear(texture(tEnvSpecular, uv0)).rgb;
    specular1 = SRGBtoLinear(texture(tEnvSpecular, uv1)).rgb;
    // RGBE == 1
    float mixRGBE = clamp(1.0 - abs(uInputType - 1.0), 0.0, 1.0);
    diffuseLight = mix(diffuseLight, RGBEToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb, mixRGBE);
    specular0 = mix(specular0, RGBEToLinear(texture(tEnvSpecular, uv0)).rgb, mixRGBE);
    specular1 = mix(specular1, RGBEToLinear(texture(tEnvSpecular, uv1)).rgb, mixRGBE);
    // RGBM == 2
    float mixRGBM = clamp(1.0 - abs(uInputType - 2.0), 0.0, 1.0);
    diffuseLight = mix(diffuseLight, RGBMToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb, mixRGBM);
    specular0 = mix(specular0, RGBMToLinear(texture(tEnvSpecular, uv0)).rgb, mixRGBM);
    specular1 = mix(specular1, RGBMToLinear(texture(tEnvSpecular, uv1)).rgb, mixRGBM);
    // RGBD == 3
    float mixRGBD = clamp(1.0 - abs(uInputType - 3.0), 0.0, 1.0);
    diffuseLight = mix(diffuseLight, RGBDToLinear(texture(tEnvDiffuse, cartesianToPolar(n))).rgb, mixRGBD);
    specular0 = mix(specular0, RGBDToLinear(texture(tEnvSpecular, uv0)).rgb, mixRGBD);
    specular1 = mix(specular1, RGBDToLinear(texture(tEnvSpecular, uv1)).rgb, mixRGBD);
    vec3 specularLight = mix(specular0, specular1, blend);
    diffuse = diffuseLight * diffuseColor;
    // Bit of extra reflection for smooth materials
    float reflectivity = pow((1.0 - roughness), 2.0) * 0.05;
    specular = specularLight * (specularColor * brdf.x + brdf.y + reflectivity);
    specular *= uEnvSpecular;
}

vec3 linearToSRGB(vec3 color) {
    return pow(color, vec3(1.0 / 2.2));
}





vec3 cosPalette(float t, vec3 a, vec3 b, vec3 c, vec3 d) {
    return a + b * cos(6.28318 * (c * t + d));
}
float blendOverlay(float base, float blend) {
    return base<0.5?(2.0*base*blend):(1.0-2.0*(1.0-base)*(1.0-blend));
}
vec3 blendOverlay(vec3 base, vec3 blend) {
    return vec3(blendOverlay(base.r, blend.r), blendOverlay(base.g, blend.g), blendOverlay(base.b, blend.b));
}
vec3 blendOverlay(vec3 base, vec3 blend, float opacity) {
    return (blendOverlay(base, blend) * opacity + base * (1.0 - opacity));
}
float blendLinearDodge(float base, float blend) {
    // Note : Same implementation as BlendAddf
    return min(base+blend,1.0);
}
vec3 blendLinearDodge(vec3 base, vec3 blend) {
    // Note : Same implementation as BlendAdd
    return min(base+blend,vec3(1.0));
}
vec3 blendLinearDodge(vec3 base, vec3 blend, float opacity) {
    return (blendLinearDodge(base, blend) * opacity + base * (1.0 - opacity));
}

void main() {
    vec3 baseColor = uBaseColor;
    // RMO map packed as rgb = [roughness, metallic, occlusion]
    vec4 rmaSample = texture2D(tRMO, vUv);
    float roughness = clamp(rmaSample.r * uRoughness, 0.04, 1.0);
    float metallic = clamp(rmaSample.g * uMetallic, 0.04, 1.0);
    vec3 f0 = vec3(0.04);
    vec3 diffuseColor = baseColor * (vec3(1.0) - f0) * (1.0 - metallic);
    vec3 specularColor = mix(f0, baseColor, metallic);
    vec3 specularEnvR0 = specularColor;
    vec3 specularEnvR90 = vec3(clamp(max(max(specularColor.r, specularColor.g), specularColor.b) * 10.0, 0.0, 1.0));
    vec3 N = getNormal();
    vec3 V = normalize(cameraPosition - vMPos);
    vec3 L = normalize(uLightDirection);
    vec3 H = normalize(L + V);
    vec3 reflection = normalize(reflect(-V, N));
    float NdL = clamp(dot(N, L), 0.001, 1.0);
    float NdV = clamp(abs(dot(N, V)), 0.001, 1.0);
    float NdH = clamp(dot(N, H), 0.0, 1.0);
    float LdH = clamp(dot(L, H), 0.0, 1.0);
    float VdH = clamp(dot(V, H), 0.0, 1.0);
    vec3 F = specularReflection(specularEnvR0, specularEnvR90, VdH);
    float G = geometricOcclusion(NdL, NdV, roughness);
    float D = microfacetDistribution(roughness, NdH);
    vec3 diffuseContrib = (1.0 - F) * (diffuseColor / PI);
    vec3 specContrib = F * G * D / (4.0 * NdL * NdV);
    // vec3 fresnelColor = rgb2hsv(vec3(0.,0.,1.));
    // fresnelColor.r += time * .05;
    // fresnelColor = hsv2rgb(fresnelColor);
    
    vec3 fresnelColor = uFesnelColor;//vec3(0.11,0.62,0.96);
    
    // vec3 fresnelColor = vec3(0.02,0.18,0.32);
    // Shading based off lights
    vec3 color = NdL * fresnelColor * (diffuseContrib + specContrib);
    // Calculate IBL lighting
    vec3 diffuseIBL;
    vec3 specularIBL;
    getIBLContribution(diffuseIBL, specularIBL, NdV, roughness, N, reflection, diffuseColor, specularColor);
    // Add IBL on top of color
    color += diffuseIBL + specularIBL;
    // Multiply occlusion
    color = mix(color, color * rmaSample.b, uOcclusion);
    float fresnelFactor = abs(dot(vViewDirection, vWorldNormal));
    float inversefresnelFactor = 1.0 - fresnelFactor ;
    fresnelFactor = pow(1. - fresnelFactor, 2.);
    inversefresnelFactor = pow(inversefresnelFactor, 4.);
    // Convert to sRGB to display
    gl_FragColor.rgb = blendLinearDodge(linearToSRGB(color * fresnelFactor), inversefresnelFactor * 1.25 * fresnelColor, .9);
    gl_FragColor.a = rangeTransition(vMPos.z + uOpacity, 1. - uOpacity, .7) * uOpacity;
}

`;

export const fragmentBallsOld = `
precision highp float;
uniform float time;
varying vec3 vNormal;
varying vec3 vPos;
varying vec2 vUv;
varying float vDistort;
varying float vAngle;
varying vec3 vMPos;
varying vec3 vWorldNormal;
varying vec3 vViewDirection;

//uniform vec3 cameraPosition;
//uniform mat4 viewMatrix;
uniform vec3 uBaseColor;
uniform sampler2D tRMO;
uniform float uMetallic;
uniform float uRoughness;
uniform float uOcclusion;
uniform sampler2D tNormal;
uniform float uNormalScale;
uniform float uNormalUVScale;
uniform sampler2D tLUT;
uniform sampler2D tEnvDiffuse;
uniform sampler2D tEnvSpecular;
uniform float uEnvSpecular;
uniform float uOpacity;
uniform float uInputType;
uniform vec3 uLightDirection;
uniform vec3 uLightColor;
uniform vec3 uFesnelColor;





const float PI = 3.14159265359;
const float RECIPROCAL_PI = 0.31830988618;
const float RECIPROCAL_PI2 = 0.15915494;
const float LN2 = 0.6931472;
const float ENV_LODS = 6.0;


${equirectUv}
${hsv2rgb}
${pnoise}
${distortAtPoint}
${rgb2hsv}
${range}


vec4 SRGBtoLinear(vec4 srgb) {
    vec3 linOut = pow(srgb.xyz, vec3(2.2));
    return vec4(linOut, srgb.w);
}
// vec3 linearToSRGB(vec3 color) {
//     return pow(color, vec3(1.0 / 2.2));
// }
// vec4 RGBEToLinear(vec4 value) {
//     return vec4(value.rgb * exp2(value.a * 255.0 - 128.0), 1.0);
// }
vec4 RGBMToLinear(vec4 value) {
    float maxRange = 6.0;
    return vec4(value.xyz * value.w * maxRange, 1.0);
}
vec4 RGBDToLinear(vec4 value) {
    float maxRange = 6.0;
    return vec4(value.rgb * ((maxRange / 255.0) / value.a), 1.0);
}
vec3 getNormal() {
    vec3 pos_dx = dFdx(vMPos.xyz);
    vec3 pos_dy = dFdy(vMPos.xyz);
    vec2 tex_dx = dFdx(vUv);
    vec2 tex_dy = dFdy(vUv);
    vec3 t = normalize(pos_dx * tex_dy.t - pos_dy * tex_dx.t);
    vec3 b = normalize(-pos_dx * tex_dy.s + pos_dy * tex_dx.s);
    mat3 tbn = mat3(t, b, normalize(vNormal));
    vec3 n = texture2D(tNormal, vUv * uNormalUVScale).rgb * 2.0 - 1.0;
    n.xy *= uNormalScale;
    vec3 normal = normalize(tbn * n);
    // Get world normal from view normal (normalMatrix * normal)
    return normalize((vec4(normal, 0.0) * viewMatrix).xyz);
}
vec3 specularReflection(vec3 specularEnvR0, vec3 specularEnvR90, float VdH) {
    return specularEnvR0 + (specularEnvR90 - specularEnvR0) * pow(clamp(1.0 - VdH, 0.0, 1.0), 5.0);
}
float geometricOcclusion(float NdL, float NdV, float roughness) {
    float r = roughness;
    float attenuationL = 2.0 * NdL / (NdL + sqrt(r * r + (1.0 - r * r) * (NdL * NdL)));
    float attenuationV = 2.0 * NdV / (NdV + sqrt(r * r + (1.0 - r * r) * (NdV * NdV)));
    return attenuationL * attenuationV;
}
float microfacetDistribution(float roughness, float NdH) {
    float roughnessSq = roughness * roughness;
    float f = (NdH * roughnessSq - NdH) * NdH + 1.0;
    return roughnessSq / (PI * f * f);
}
vec2 cartesianToPolar(vec3 n) {
    vec2 uv;
    uv.x = atan(n.z, n.x) * RECIPROCAL_PI2 + 0.5;
    uv.y = asin(n.y) * RECIPROCAL_PI + 0.5;
    return uv;
}
void getIBLContribution(inout vec3 diffuse, inout vec3 specular, float NdV, float roughness, vec3 n, vec3 reflection, vec3 diffuseColor, vec3 specularColor) {
    vec3 brdf = SRGBtoLinear(texture2D(tLUT, vec2(NdV, roughness))).rgb;
    // Sample 2 levels and mix between to get smoother degradation
    float blend = roughness * ENV_LODS;
    float level0 = floor(blend);
    float level1 = min(ENV_LODS, level0 + 1.0);
    blend -= level0;
    // Sample the specular env map atlas depending on the roughness value
    vec2 uvSpec = cartesianToPolar(reflection);
    uvSpec.y /= 2.0;
    vec2 uv0 = uvSpec;
    vec2 uv1 = uvSpec;
    uv0 /= pow(2.0, level0);
    uv0.y += 1.0 - exp(-LN2 * level0);
    uv1 /= pow(2.0, level1);
    uv1.y += 1.0 - exp(-LN2 * level1);
    vec3 diffuseLight;
    vec3 specular0;
    vec3 specular1;
    // 'If else' statements caused the strangest gpu bug
    // if (uInputType < 0.5) {
    //
    //
    // sRGB == 0
    //     diffuseLight = SRGBToLinear(texture2D(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = SRGBToLinear(texture2D(tEnvSpecular, uv0)).rgb;
    //     specular1 = SRGBToLinear(texture2D(tEnvSpecular, uv1)).rgb;
    // } else if (uInputType < 1.5) {
    //
    //
    // RGBE == 1
    //     diffuseLight = RGBEToLinear(texture2D(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = RGBEToLinear(texture2D(tEnvSpecular, uv0)).rgb;
    //     specular1 = RGBEToLinear(texture2D(tEnvSpecular, uv1)).rgb;
    // } else if (uInputType < 2.5) {
    //
    //
    // RGBM == 2
    //     diffuseLight = RGBMToLinear(texture2D(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = RGBMToLinear(texture2D(tEnvSpecular, uv0)).rgb;
    //     specular1 = RGBMToLinear(texture2D(tEnvSpecular, uv1)).rgb;
    // } else if (uInputType < 3.5) {
    //
    //
    // RGBD == 3
    //     diffuseLight = RGBDToLinear(texture2D(tEnvDiffuse, cartesianToPolar(n))).rgb;
    //     specular0 = RGBDToLinear(texture2D(tEnvSpecular, uv0)).rgb;
    //     specular1 = RGBDToLinear(texture2D(tEnvSpecular, uv1)).rgb;
    // }
    // sRGB == 0
    diffuseLight = SRGBtoLinear(texture2D(tEnvDiffuse, cartesianToPolar(n))).rgb;
    specular0 = SRGBtoLinear(texture2D(tEnvSpecular, uv0)).rgb;
    specular1 = SRGBtoLinear(texture2D(tEnvSpecular, uv1)).rgb;
    // RGBE == 1
    float mixRGBE = clamp(1.0 - abs(uInputType - 1.0), 0.0, 1.0);
    diffuseLight = mix(diffuseLight, RGBEToLinear(texture2D(tEnvDiffuse, cartesianToPolar(n))).rgb, mixRGBE);
    specular0 = mix(specular0, RGBEToLinear(texture2D(tEnvSpecular, uv0)).rgb, mixRGBE);
    specular1 = mix(specular1, RGBEToLinear(texture2D(tEnvSpecular, uv1)).rgb, mixRGBE);
    // RGBM == 2
    float mixRGBM = clamp(1.0 - abs(uInputType - 2.0), 0.0, 1.0);
    diffuseLight = mix(diffuseLight, RGBMToLinear(texture2D(tEnvDiffuse, cartesianToPolar(n))).rgb, mixRGBM);
    specular0 = mix(specular0, RGBMToLinear(texture2D(tEnvSpecular, uv0)).rgb, mixRGBM);
    specular1 = mix(specular1, RGBMToLinear(texture2D(tEnvSpecular, uv1)).rgb, mixRGBM);
    // RGBD == 3
    float mixRGBD = clamp(1.0 - abs(uInputType - 3.0), 0.0, 1.0);
    diffuseLight = mix(diffuseLight, RGBDToLinear(texture2D(tEnvDiffuse, cartesianToPolar(n))).rgb, mixRGBD);
    specular0 = mix(specular0, RGBDToLinear(texture2D(tEnvSpecular, uv0)).rgb, mixRGBD);
    specular1 = mix(specular1, RGBDToLinear(texture2D(tEnvSpecular, uv1)).rgb, mixRGBD);
    vec3 specularLight = mix(specular0, specular1, blend);
    diffuse = diffuseLight * diffuseColor;
    // Bit of extra reflection for smooth materials
    float reflectivity = pow((1.0 - roughness), 2.0) * 0.05;
    specular = specularLight * (specularColor * brdf.x + brdf.y + reflectivity);
    specular *= uEnvSpecular;
}






vec3 linearToSRGB(vec3 color) {
    return pow(color, vec3(1.0 / 2.2));
}

vec3 cosPalette(float t, vec3 a, vec3 b, vec3 c, vec3 d) {
    return a + b * cos(6.28318 * (c * t + d));
}
float blendOverlay(float base, float blend) {
    return base<0.5?(2.0*base*blend):(1.0-2.0*(1.0-base)*(1.0-blend));
}
vec3 blendOverlay(vec3 base, vec3 blend) {
    return vec3(blendOverlay(base.r, blend.r), blendOverlay(base.g, blend.g), blendOverlay(base.b, blend.b));
}
vec3 blendOverlay(vec3 base, vec3 blend, float opacity){
    return (blendOverlay(base, blend) * opacity + base * (1.0 - opacity));
}
float blendLinearDodge(float base, float blend) {
    // Note : Same implementation as BlendAddf
    return min(base+blend,1.0);
}
vec3 blendLinearDodge(vec3 base, vec3 blend) {
    // Note : Same implementation as BlendAdd
    return min(base+blend,vec3(1.0));
}
vec3 blendLinearDodge(vec3 base, vec3 blend, float opacity) {
    return (blendLinearDodge(base, blend) * opacity + base * (1.0 - opacity));
}
void main() {
    vec3 baseColor = uBaseColor;
    // RMO map packed as rgb = [roughness, metallic, occlusion]
    vec4 rmaSample = texture2D(tRMO, vUv);
    float roughness = clamp(rmaSample.r * uRoughness, 0.04, 1.0);
    float metallic = clamp(rmaSample.g * uMetallic, 0.04, 1.0);
    vec3 f0 = vec3(0.04);
    vec3 diffuseColor = baseColor * (vec3(1.0) - f0) * (1.0 - metallic);
    vec3 specularColor = mix(f0, baseColor, metallic);
    vec3 specularEnvR0 = specularColor;
    vec3 specularEnvR90 = vec3(clamp(max(max(specularColor.r, specularColor.g), specularColor.b) * 10.0, 0.0, 1.0));
    vec3 N = getNormal();
    vec3 V = normalize(cameraPosition - vMPos);
    vec3 L = normalize(uLightDirection);
    vec3 H = normalize(L + V);
    vec3 reflection = normalize(reflect(-V, N));
    float NdL = clamp(dot(N, L), 0.001, 1.0);
    float NdV = clamp(abs(dot(N, V)), 0.001, 1.0);
    float NdH = clamp(dot(N, H), 0.0, 1.0);
    float LdH = clamp(dot(L, H), 0.0, 1.0);
    float VdH = clamp(dot(V, H), 0.0, 1.0);
    vec3 F = specularReflection(specularEnvR0, specularEnvR90, VdH);
    float G = geometricOcclusion(NdL, NdV, roughness);
    float D = microfacetDistribution(roughness, NdH);
    vec3 diffuseContrib = (1.0 - F) * (diffuseColor / PI);
    vec3 specContrib = F * G * D / (4.0 * NdL * NdV);

    vec3 fresnelColor = uFesnelColor;

    // vec3 fresnelColor = rgb2hsv(vec3(0.,0.,1.));
    // fresnelColor.r += time * .05;
    // fresnelColor = hsv2rgb(fresnelColor);
    // Shading based off lights
    vec3 color = NdL * fresnelColor * (diffuseContrib + specContrib);
    // Calculate IBL lighting
    vec3 diffuseIBL;
    vec3 specularIBL;
    getIBLContribution(diffuseIBL, specularIBL, NdV, roughness, N, reflection, diffuseColor, specularColor);
    // Add IBL on top of color
    color += diffuseIBL + specularIBL;
    // Multiply occlusion
    color = mix(color, color * rmaSample.b, uOcclusion);
    float fresnelFactor = abs(dot(vViewDirection, vWorldNormal));
    float inversefresnelFactor = 1.0 - fresnelFactor ;
    fresnelFactor = pow(1. - fresnelFactor, 2.);
    inversefresnelFactor = pow(inversefresnelFactor, 4.);
    // Convert to sRGB to display
    gl_FragColor.rgb = blendLinearDodge(linearToSRGB(color * fresnelFactor), inversefresnelFactor * 1.25 * fresnelColor, .9);
    gl_FragColor.a = rangeTransition(vMPos.z + uOpacity, 1. - uOpacity, .7) * uOpacity;
}
`;