import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as dat from 'dat.gui'
import gsap from "gsap";
import {
    vertexBackground,
    fragmentBackground,
    vertexBalls,
    fragmentBalls,
    fragmentBallsOld,
} from "./constants.js"

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

let camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.01, 1000);
const scene = new THREE.Scene();
const canvas = document.querySelector(".webgl");
const loader = new GLTFLoader();
let lightBack, lightRight;

const params = {
    deltaTime: 0.01,
    // material
    material: {
        balls: {
            uColor: "#1B9EF4",
            uScaleDist: 1,
            uTransition: 0.5,
            uNoiseDensity: 0.1,
            uNoiseStrength: 0.1,
            uMetallic:  0.0,
            uRoughness: 0.1,
            uOcclusion:  0.0,
            uNormalScale:  0.0,
            uNormalUVScale: 200,
            uInputType: 0,
            uEnvSpecular: 20,
            uOpacity: 1.0,
            distort: 0.15,
            radius: 1 ,
            frequency: 0.3,
            speed: 1,
            surfaceDistort: 2.03,
            surfaceFrequency: 0.3,
            surfaceTime: 0.5,
            surfaceSpeed: 0.5 ,
            numberOfWaves: 2 ,
            fixNormals: !0,
            surfacePoleAmount: 1,
            gooPoleAmount: 5,

            // roughness: 0.25,
            // metalness: 1.0,
        },
        background: {
            uColor: "#062D51",
            uTransition: 0.8,
            uRatio: {
                x: 5,
                y: 5,
            },
        },
    },
    // lights
    lb: {
        x: 0.5,
        y: 6,
        z: 4.5,
        intensity: 1,
    },
    lr:{
        x: 10,
        y: 0,
        z: 0,
        intensity: 1,
    },
    // camera
    cam: {
        x: 0,
        y: 0,
        z: 30,
    }
};

let pixelRatio = window.devicePixelRatio
let AA = true
if (pixelRatio > 1) {
    AA = false
}
const renderer = new THREE.WebGLRenderer({
    antialias: AA,
    powerPreference: "high-performance",
    canvas: canvas,
    // alpha: true
});

let mixer,
    clock,
    pngCubeRenderTarget,
    material,
    backgroundPlane,
    orbit,
    deltaPositionBackground = 0.01,
    animationAction = {timeScale:0},
    currentTime = 0,
    guiStatsEl,
    gltfScene,
    shaderMaterial,
    backgroundMaterial,
    sphere;

clock = new THREE.Clock();

const scaleCanvas = 20; // %

const sections = [
    document.getElementById("our_vision_block"),
    document.getElementById("Careers_Blocks"),
    document.getElementById("our_objectives_block"),
    document.getElementById("cooperate"),
    document.getElementById("contacts_block"),
]

const timeLine = [
    {
        time: 0,
        scroll: 0, // Bottom limit of comparison. The top one is taken from the previous block
        delta_scroll: 0, // Percentages relative to screen height. If negative then add up, positive down.
    },
    {
        time: 2.5,//4.63333,
        scroll: 0,
        delta_scroll: 0,
    },
    {
        time: 5.33333,//9.28333,
        scroll: 0,
        delta_scroll: 0,
    },
    {
        time: 8.33333,//13.88333,
        scroll: 0,
    },
    {
        time: 13.66666,//18.53333,
        scroll: 0,
        delta_scroll: 0,
    },
    {
        time: 15.66666,//22.8,
        scroll: 0,
        delta_scroll: 0,
    },
];

const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

const breakPoints = [
    // 240 - 479
    {
        width: 480,
        scaleFactor: 0.5,
        scale: { x: 0.1, y: 0.1, z: 0.1},
        // scale: { x: 0.2, y: 0.2, z: 0.2},
        position: { x: 0, y: 0.0, z: 0},
        rotation: { x: 0.06, y: 0, z: 0},
    },
    // 480 - 767
    {
        width: 768,
        scaleFactor: 0.5,
        scale: { x: 0.3, y: 0.3, z: 0.3},
        position: { x: 0, y: -0.2, z: 0},
        rotation: { x: 0.06, y: 0, z: 0},
    },
    // 768 - 991
    {
        width: 992,
        scaleFactor: 1,
        scale: { x: 0.6, y: 0.6, z: 0.6},
        // scale: { x: 0.5, y: 0.5, z: 0.5},
        position: { x: 0, y: 0.0, z: 0},
        rotation: { x: 0.04, y: 0, z: 0},
    },
    // 992 - 1439 and up
    {
        width: 1440,
        scaleFactor: 1,
        scale: { x: 0.6, y: 0.6, z: 0.6},
        // scale: { x: 0.5, y: 0.5, z: 0.5},
        position: { x: 0, y: 0.0, z: 0},
        // position: { x: 0, y: -0.2, z: 0},
        rotation: { x: 0.0, y: 0, z: 0},
    },
]

window.addEventListener('load', init, false);
function init() {
    initScrollPositions();
    createWorld();
    createLights();
    createPrimitiveAndMaterials();
    // createGUI();
    loadGLTF();
    //---
    animation();
}

function initScrollPositions() {
    const scrollTop = $(window).scrollTop();

    sections.forEach((section, index) => {
        const deltaScroll = timeLine[index].delta_scroll ? (sizes.height * timeLine[index].delta_scroll)/100 : 0;
        const rect = section.getBoundingClientRect();
        timeLine[index].scroll = rect.top + scrollTop + deltaScroll;
    });
    timeLine[5].scroll = $(document).height();
};

function createWorld() {
    camera.position.x = params.cam.x;
    camera.position.y = params.cam.y;
    camera.position.z = params.cam.z;
    scene.add(camera);

    orbit = new THREE.Object3D();
    orbit.rotation.order = "YXZ";
    orbit.position.copy( {x: 0, y: 0, z: 0} );
    scene.add(orbit);
    orbit.add( camera );

    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( sizes.width + (sizes.width * scaleCanvas) / 100, sizes.height + (sizes.height * scaleCanvas) / 100 );
    // renderer.setSize( sizes.width, sizes.height);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.outputEncoding = THREE.sRGBEncoding;

    window.addEventListener( 'resize', onWindowResize );
    window.addEventListener( 'scroll', onWindowScroll );
    document.addEventListener('mousemove', onMouseMove );
}

let isScrolling;

function onWindowScroll() {

    const scrollTop = $(window).scrollTop();

    deltaPositionBackground = 0.02;

    window.clearTimeout( isScrolling );
    isScrolling = setTimeout(function() {

        let startScroll = 0;
        for (let i = 0; i < timeLine.length; i++ ) {
            if ((scrollTop >= startScroll) && (scrollTop < timeLine[i].scroll)) {

                currentTime = timeLine[i].time;
                const progressPercent = (Math.abs(animationAction.time - currentTime)/timeLine[timeLine.length-1].time)*100;

                let speed = 1;
                if (progressPercent > 60) {

                    const x = gltfScene.scale.x, y = gltfScene.scale.y, z = gltfScene.scale.z;
                    gsap.to(gltfScene.scale, {
                        duration: 0.3, x: 0, y: 0, z: 0,
                        onComplete: () => {
                            animationAction.time = timeLine[i].time;
                            gsap.to(gltfScene.scale, {
                                duration: 0.3, x: x, y: y, z: z, });
                        }
                    });

                } else {
                    progressPercent > 30 ? speed = 2 : speed = 1;
                    animationAction.time - currentTime >= 0 ?
                        animationAction.timeScale = -speed : animationAction.timeScale = speed;
                }
            }
            startScroll = timeLine[i].scroll;
        }

        deltaPositionBackground = 0.01;
    }, 50);
}

function onMouseMove(e) {
    let scale = 0.000001;
    orbit.rotateY( e.movementX * scale );
    orbit.rotateX( e.movementY * scale );
    orbit.rotation.z = 0; //this is important to keep the camera level.
}

function onWindowResize() {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();
    // renderer.setSize( sizes.width, sizes.height );
    renderer.setSize( sizes.width + (sizes.width * scaleCanvas) / 100, sizes.height + (sizes.height * scaleCanvas) / 100 );
    initScrollPositions();

    if (gltfScene) {
        let initWidth = 0;
        for (let i = 0; i < breakPoints.length; i++) {
            if ((sizes.width >= initWidth) && (sizes.width < breakPoints[i].width) ||
                (sizes.width > breakPoints[i].width) && (i === breakPoints.length - 1)) {
                let scaleTo, positionTo, rotationTo;
                // scale factor render resolution
                const scale = (breakPoints[i].scaleFactor > 1 && isMobile) ? 1 : breakPoints[i].scaleFactor;
                renderer.setPixelRatio(window.devicePixelRatio * scale);
                // scale
                breakPoints[i].scale ?
                    scaleTo = new THREE.Vector3(
                        breakPoints[i].scale.x,
                        breakPoints[i].scale.y,
                        breakPoints[i].scale.z) :
                    scaleTo = new THREE.Vector3();
                gsap.to(gltfScene.scale, {
                    duration: 1,
                    x: scaleTo.x,
                    y: scaleTo.y,
                    z: scaleTo.z,
                });
                // position
                breakPoints[i].position ?
                    positionTo = new THREE.Vector3(
                        breakPoints[i].position.x,
                        breakPoints[i].position.y,
                        breakPoints[i].position.z) :
                    positionTo = new THREE.Vector3();
                gsap.to(gltfScene.position, {
                    duration: 1,
                    x: positionTo.x,
                    y: positionTo.y,
                    z: positionTo.z,
                });
                // rotation
                breakPoints[i].rotation ?
                    rotationTo = new THREE.Vector3(
                        breakPoints[i].rotation.x,
                        breakPoints[i].rotation.y,
                        breakPoints[i].rotation.z) :
                    rotationTo = new THREE.Vector3();
                gsap.to(gltfScene.rotation, {
                    duration: 1,
                    x: rotationTo.x,
                    y: rotationTo.y,
                    z: rotationTo.z,
                });

                break;
            }
            initWidth = breakPoints[i].width;
        }
    }
}

function createPrimitiveAndMaterials() {

    backgroundMaterial = new THREE.ShaderMaterial({
        extensions: {
            derivatives: "#extension GL_OES_standard_derivatives : enable"
        },
        side: THREE.DoubleSide,
        uniforms: {
            time: {type: "f", value: 0},
            uColor: {type: "v3", value: new THREE.Color(params.material.background.uColor)},
            uRatio: {type: "v2", value: new THREE.Vector2(
                    params.material.background.uRatio.x,
                    params.material.background.uRatio.y,
                )},
            uTransition: {type: "f", value: params.material.background.uTransition},
            resolution: {type: "v4", value: new THREE.Vector4()},
            uvRate1: {
                value: new THREE.Vector2(1,1)
            },
        },
        // wireframe: true,
        vertexShader: vertexBackground,
        fragmentShader: fragmentBackground,
    });

    let geometry = new THREE.PlaneBufferGeometry( 100, 100, 100, 100); // x = window.innerWidth / 2
    backgroundPlane = new THREE.Mesh(geometry, backgroundMaterial);
    backgroundPlane.position.set(0,-32,-50); // 0, 140, -280);
    backgroundPlane.rotation.x = -0.61; // 35 deg
    scene.add(backgroundPlane);

    //shader material

    // const texture = new THREE.Texture();
    const texture = new THREE.TextureLoader().load( 'images/HDRI.png');

    shaderMaterial = new THREE.ShaderMaterial({
        extensions: {
            // derivatives: "#extension GL_OES_standard_derivatives : enable"
        },
        side: THREE.DoubleSide,
        transparent: false,
        uniforms: {
            time: {type: "f", value: 0},
            uRatio: {type: "v2", value:new THREE.Vector2(1,1)},
            uTransition: {type: "f", value: params.material.balls.uTransition},
            uScaleDist: {type: "f", value: params.material.balls.uScaleDist},
            resolution: {type: "v4", value: new THREE.Vector4()},

            uNoiseDensity: {type: "f", value: params.material.balls.uNoiseDensity},
            uNoiseStrength: {type: "f", value: params.material.balls.uNoiseStrength},

            uFesnelColor: {type: 'v3', value: new THREE.Color(params.material.balls.uColor)},//params.material.balls.uColor},
            uBaseColor: {type: "v3", value:new THREE.Vector3(0.0,0.0, 0.0)},
            tRMO: {value: texture},
            uMetallic: {type: "f", value: params.material.balls.uMetallic},
            uRoughness: {type: "f", value: params.material.balls.uRoughness},
            uOcclusion: {type: "f", value: params.material.balls.uOcclusion},
            uNormalScale: {type: "f", value: params.material.balls.uNormalScale},
            uNormalUVScale: {type: "f", value: params.material.balls.uNormalUVScale},
            uInputType: {type: "f", value: params.material.balls.uInputType},
            uEnvSpecular: {type: "f", value: params.material.balls.uEnvSpecular},
            tNormal: {value: texture},
            tLUT: {value: texture},
            tEnvDiffuse: {value: texture},
            tEnvSpecular: {value: texture},
            uOpacity: {type: "f", value: params.material.balls.uOpacity},
            uLightDirection: {type: "v3", value: new THREE.Vector3(0.0,1.0, 1.0)},
            uLightColor: {type: "v3", value:new THREE.Vector3(1.0,1.0, 1.0)},

            distort: { value: params.material.balls.distort },
            radius: { value: params.material.balls.radius },
            frequency: { value: params.material.balls.frequency },
            speed: { value: params.material.balls.speed },
            surfaceDistort: { value: params.material.balls.surfaceDistort },
            surfaceFrequency: { value: params.material.balls.surfaceFrequency },
            surfaceTime: {value: params.material.balls.surfaceTime },
            surfaceSpeed: { value: params.material.balls.surfaceSpeed },
            numberOfWaves: { value: params.material.balls.numberOfWaves },
            fixNormals: { value: !0 },
            surfacePoleAmount: { value: params.material.balls.surfacePoleAmount },
            gooPoleAmount: { value: params.material.balls.gooPoleAmount },

            uvRate1: {
                value: new THREE.Vector2(1,1)
            },
        },
        // wireframe: true,
        vertexShader: vertexBalls,
        fragmentShader: fragmentBallsOld//fragmentBalls,
    });
    // console.log("isWebgl2 - "+renderer.isWebGL2);
    shaderMaterial.extensions.derivatives = true;
}

function createLights() {
    // back
    lightBack = new THREE.DirectionalLight(0xffffff, params.lb.intensity);
    lightBack.target.position.set( 0,0,0 );
    lightBack.position.set(params.lb.x, params.lb.y, params.lb.z);
    // scene.add(lightBack);
    // side
    lightRight = new THREE.DirectionalLight(0xffffff, params.lr.intensity);
    lightRight.target.position.set( 0, 0, 0 );
    lightRight.position.set(params.lr.x, params.lr.y, params.lr.z);
    // scene.add(lightRight);

    // scene.add(new THREE.AmbientLight(0xffffff, 2));
}

function loadGLTF() {

    loader.load('./js/H3_Sphere_Animation_04.gltf',
        function (gltf) {
            console.log("gltf - ", gltf);
            gltfScene = gltf.scene;

            camera = gltf.cameras[0];
            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();
            orbit.add( camera );

            mixer = new THREE.AnimationMixer(gltf.scene);

            gltfScene.traverse((object) => {
                if (object.name.toLowerCase().includes("sphere_")) {
                    object.material = shaderMaterial;
                }
            });

            animationAction = mixer.clipAction(gltf.animations[0]);
            animationAction.play();
            animationAction.timeScale = 0;
            animationAction.clipWhenFinished = true;

            const scrollTop = $(window).scrollTop();
            let startScroll = 0;
            for (let i = 0; i < timeLine.length; i++ ) {
                if ((scrollTop >= startScroll) && (scrollTop < timeLine[i].scroll)) {
                    animationAction.time = currentTime = timeLine[i].time;
                }
                startScroll = timeLine[i].scroll;
            }
            onWindowResize();
            scene.add(gltfScene);
        },
        function (xhr) {
            console.log('xhr', xhr)
        },
        function (error) {
            console.log('error', error)
        }
    )
}

function createGUI() {
    const gui = new dat.GUI();

    const ballFolder = gui.addFolder('Material for ball');
    ballFolder.addColor(params.material.balls, 'uColor').onChange(function(value) {
        params.material.balls.uColor = value;
    });
    // ballFolder.add(params.material.balls, 'uTransition').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'uScaleDist').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'frequency').min(0).max(1).step(0.01);
    // ballFolder.add(params.material.balls, 'uNoiseDensity').min(0).max(1).step(0.01);
    // ballFolder.add(params.material.balls, 'uNoiseStrength').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'uMetallic').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'uRoughness').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'uOcclusion').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'uNormalScale').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'uNormalUVScale').min(0).max(200).step(1);
    // ballFolder.add(params.material.balls, 'uInputType').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'uEnvSpecular').min(0).max(40).step(0.1);
    // ballFolder.add(params.material.balls, 'uOpacity').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'distort').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'radius').min(0).max(3).step(0.01);
    // ballFolder.add(params.material.balls, 'speed').min(0).max(3).step(0.01);
    ballFolder.add(params.material.balls, 'surfaceDistort').min(0).max(10).step(0.01);
    ballFolder.add(params.material.balls, 'surfaceFrequency').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'surfaceTime').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'surfaceSpeed').min(0).max(1).step(0.01);
    ballFolder.add(params.material.balls, 'numberOfWaves').min(0).max(10).step(0.01);
    ballFolder.add(params.material.balls, 'surfacePoleAmount').min(0).max(5).step(0.01);
    ballFolder.add(params.material.balls, 'gooPoleAmount').min(0).max(10).step(0.01);

    ballFolder.open();

    const backgroundFolder = gui.addFolder('Material for background');
    backgroundFolder.addColor(params.material.background, 'uColor').onChange(function(value) {
        params.material.background.uColor = value;
    });
    backgroundFolder.add(params.material.background, 'uTransition').min(0).max(1).step(0.001);
    backgroundFolder.add(params.material.background.uRatio, 'x').min(0).max(10).step(0.01);
    backgroundFolder.add(params.material.background.uRatio, 'y').min(0).max(10).step(0.01);

    backgroundFolder.open();

    const otherFolder = gui.addFolder('Other');
    otherFolder.add(params, 'deltaTime').min(0.0001).max(0.1).step(0.0001);

    otherFolder.open();

    const el = document.querySelector(".dg.ac");
    el.style.zIndex = "100";
    console.log(el);



    // const lightBackFolder = gui.addFolder('Light Back');
    // lightBackFolder.add(lightBack.position, 'x').min(-30).max(30).step(0.5);
    // lightBackFolder.add(lightBack.position, 'y').min(-30).max(30).step(0.5);
    // lightBackFolder.add(lightBack.position, 'z').min(-30).max(30).step(0.5);
    // lightBackFolder.add(lightBack, 'intensity').min(0).max(10).step(0.1);
    //
    // // const lightBackHelper = new THREE.DirectionalLightHelper(lightBack, 0.5);
    // // scene.add(lightBackHelper);
    //
    // const lightRightFolder = gui.addFolder('Light Right');
    // lightRightFolder.add(lightRight.position, 'x').min(-30).max(30).step(0.5);
    // lightRightFolder.add(lightRight.position, 'y').min(-30).max(30).step(0.5);
    // lightRightFolder.add(lightRight.position, 'z').min(-30).max(30).step(0.5);
    // lightRightFolder.add(lightRight, 'intensity').min(0).max(10).step(0.1);

    // const perfFolder = gui.addFolder( 'Performance' );

    // guiStatsEl = document.createElement( 'div' );
    // guiStatsEl.classList.add( 'gui-stats' );

    // perfFolder.domElement.appendChild( guiStatsEl );
    // console.log(perfFolder);
    // perfFolder.open();

    // const lightRightHelper = new THREE.DirectionalLightHelper(lightRight, 0.5);
    // scene.add(lightRightHelper);
    // const axesHelper = new THREE.AxesHelper( 3 );
    // scene.add( axesHelper );
}

let time = 0;
function animation() {

    requestAnimationFrame(animation);

    time += params.deltaTime;
    shaderMaterial.uniforms.time.value = time;
    shaderMaterial.uniforms.surfaceTime.value = time;
    backgroundMaterial.uniforms.time.value = time;

    // updateParameters();

    if (Math.abs(animationAction.time - currentTime) < 0.1) {
        animationAction.timeScale = 0;
    }

    if (mixer) mixer.update(clock.getDelta())

    renderer.render(scene, camera);
}

function updateParameters() {
    shaderMaterial.uniforms.uTransition.value = params.material.balls.uTransition;
    shaderMaterial.uniforms.uScaleDist.value = params.material.balls.uScaleDist;
    shaderMaterial.uniforms.uFesnelColor.value = new THREE.Color(params.material.balls.uColor);
    shaderMaterial.uniforms.frequency.value = params.material.balls.frequency;
    shaderMaterial.uniforms.uNoiseDensity.value = params.material.balls.value = params.material.balls.uNoiseDensity
    shaderMaterial.uniforms.uNoiseStrength.value = params.material.balls.value = params.material.balls.uNoiseStrength
    shaderMaterial.uniforms.uMetallic.value = params.material.balls.value = params.material.balls.uMetallic
    shaderMaterial.uniforms.uRoughness.value = params.material.balls.value = params.material.balls.uRoughness
    shaderMaterial.uniforms.uOcclusion.value = params.material.balls.value = params.material.balls.uOcclusion
    shaderMaterial.uniforms.uNormalScale.value = params.material.balls.value = params.material.balls.uNormalScale
    shaderMaterial.uniforms.uNormalUVScale.value = params.material.balls.uNormalUVScale
    shaderMaterial.uniforms.uInputType.value = params.material.balls.uInputType
    shaderMaterial.uniforms.uEnvSpecular.value = params.material.balls.uEnvSpecular
    shaderMaterial.uniforms.uOpacity.value = params.material.balls.uOpacity
    shaderMaterial.uniforms.distort.value = params.material.balls.distort
    shaderMaterial.uniforms.radius.value = params.material.balls.radius
    shaderMaterial.uniforms.speed.value = params.material.balls.speed
    shaderMaterial.uniforms.surfaceDistort.value = params.material.balls.surfaceDistort
    shaderMaterial.uniforms.surfaceFrequency.value = params.material.balls.surfaceFrequency
    shaderMaterial.uniforms.surfaceTime.value = params.material.balls.surfaceTime
    shaderMaterial.uniforms.surfaceSpeed.value = params.material.balls.surfaceSpeed
    shaderMaterial.uniforms.numberOfWaves.value = params.material.balls.numberOfWaves
    shaderMaterial.uniforms.surfacePoleAmount.value = params.material.balls.surfacePoleAmount
    shaderMaterial.uniforms.gooPoleAmount.value = params.material.balls.gooPoleAmount

    backgroundMaterial.uniforms.uColor.value = new THREE.Color(params.material.background.uColor);
    backgroundMaterial.uniforms.uTransition.value = params.material.background.uTransition;
    backgroundMaterial.uniforms.uRatio.value = new THREE.Vector2(
        params.material.background.uRatio.x,
        params.material.background.uRatio.y,
    );
}